import request from "../utils/request";
import requestOther from "../utils/requestOther";
import {resize} from "mathjs";

// let vue_index = 1;
let change_url = 'robot';

export const getIndex = (index) =>{
    if (index == 1){
        // change_url = 'robot'
        change_url = 'okex'
    }else{
        change_url = 'okex'
    }
}
export {change_url}

/**
 * 获取机器人状态
 * @returns {AxiosPromise}
 */
export const getRobotInfo = (robotType) => request({
    method: 'GET',
    url: '/'+ change_url +'/getRobotInfo',
    params: {robotType}
})
/**
 * 關閉機器人
 * @returns {AxiosPromise}
 */
export const getCloseRobot = (robotType) => request({
    method: 'GET',
    url: '/'+ change_url +'/stopRobot',
    params: {robotType}
})
/**
 * 開啓機器人
 * @returns {AxiosPromise}
 */
export const getOpenRobot = (robotType) => request({
    method: 'GET',
    url: '/'+ change_url +'/startRobot',
    params: {robotType}
})

/**
 * 获取资产分布
 * @returns {AxiosPromise}
 */
export const getAssets = (robotType) => request({
    method: 'GET',
    url: '/'+ change_url +'/getAssets',
    params: {robotType}
})

/**
 * 获取所有幣種
 * @returns {AxiosPromise}
 */
export const getAllCoins = () => request({
    method: 'GET',
    url: '/'+ change_url +'/getAllCoins',
})
/**
 * 获取U下的所有币种
 * @returns {AxiosPromise}
 */
export const getAllCoinsUb = () => request({
    method: 'GET',
    url: '/'+ change_url +'/getAllCoinsUb',
})
/**
 * 获取所有幣種
 * @returns {AxiosPromise}
 */
export const getAllSymbolsByCoin = (coin) => request({
    method: 'GET',
    url: '/'+ change_url +'/getAllSymbolsByCoin',
    params:{coin}
})
/**
 * 获取所有幣種
 * @returns {AxiosPromise}
 */
export const getAllSymbolsByCoin2 = (coin_one,coin_two) => request({
    method: 'GET',
    url: '/'+ change_url +'/getThirdSymbolsByCoins',
    params:{coin_one,coin_two}
})
/**
 * 添加黄金三角
 * @returns {AxiosPromise}
 */
export const getAddSj = (coin_one,coin_two,coin_three,amount_one,amount_two,amount_three, state) => request({
    method: 'GET',
    url: '/'+ change_url +'/addSj',
    params:{coin_one,coin_two,coin_three,amount_one,amount_two,amount_three, state}
})
/**
 * 添加UB
 * @returns {AxiosPromise}
 */
export const getAddUb = (coin,amount) => request({
    method: 'GET',
    url: '/'+ change_url +'/addUb',
    params:{coin,amount}
})
/**
 * 添加BB
 * @returns {AxiosPromise}
 */
export const getAddBb = (coin_one,coin_two,amount_one,amount_two, state) => request({
    method: 'GET',
    url: '/'+ change_url +'/addBb',
    params:{coin_one,coin_two,amount_one,amount_two,state}
})
/**
 * 修改BB仓位
 * @returns {AxiosPromise}
 */
export const modifyBb = (coin_one,coin_two,amount_one,amount_two) => request({
    method: 'GET',
    url: '/'+ change_url +'/modifyBb',
    params:{coin_one,coin_two,amount_one,amount_two}
})

/**
 * 添加合约
 * @param robotType
 * @param space
 * @returns {AxiosPromise}
 */
export const addSupremeHedge = (robotType, space, stopLoss, stopSurplus, state, coin, percentageValue, positionProportion, speed) => request({
    method: 'POST',
    url: 'robot/addSupremeHedge',
    params: {robotType, space, stopLoss, stopSurplus, coin, percentageValue, positionProportion, speed}
})

/**
 * 修改仓位
 * @param {*} robotType
 * @param {*} sid
 * @param {*} space
 */
export const modifyFuturesSpace = (robotType, space, sid, stopLoss, lossTransferRate, positionProportion) => request({
    method: 'GET',
    url: '/robot/modifyFuturesSpace',
    params: {robotType, space, sid, stopLoss, lossTransferRate, positionProportion}
})

/**
 * 添加网格
 */
export const getAddGrid = (baseCoin,quoteCoin,minPrice,maxPrice,gridNumber,baseCoinSpace,quoteCoinSpace,gridMode,robotType)=> request({
    method: 'post',
    url: '/grid/addGridStrategy',
    params:{baseCoin,quoteCoin,minPrice,maxPrice,gridNumber,baseCoinSpace,quoteCoinSpace,gridMode,robotType}
})
/**
 * 获取黄金三角仓位信息
 * @returns {AxiosPromise}
 */
export const getSj = () => request({
    method: 'GET',
    url: '/'+ change_url +'/getSj',
})
/**
 * 获取BB仓位信息
 * @returns {AxiosPromise}
 */
export const getBb = () => request({
    method: 'GET',
    url: '/'+ change_url +'/getBb',
})
/**
 * 获取UB仓位信息
 * @returns {AxiosPromise}
 */
export const getUb = () => request({
    method: 'GET',
    url: '/'+ change_url +'/getUb',
})
/**
 * 获取策略列表
 */
export const getGrid = (robotType) => request({
    method: 'post',
    url: '/grid/getGridStrategyList',
    params:{robotType}
})
/**
 * 獲取總的策略列表數據
 * @param robotType
 * @returns {AxiosPromise}
 */
export const getUserStrategyListAll = (requestTimes, robotType, isProfit) => request({
    method: 'POST',
    url: '/robot/getUserStrategyListAll',
    params:{requestTimes, robotType, isProfit}
})
/**
 * 刪除黃金三角
 * @returns {AxiosPromise}
 */
export const delSj = (belong_to2) => request({
    method: 'GET',
    url: '/'+ change_url +'/delSj',
    params:{belong_to2}
})
/**
 * 刪除BB
 * @returns {AxiosPromise}
 */
export const delBb = (coin, state) => request({
    method: 'GET',
    url: '/'+ change_url +'/delBb',
    params:{coin, state}
})
/**
 * 刪除UB  传的id是仓位id，不是用户id
 * @returns {AxiosPromise}
 */
export const delUb = (id) => request({
    method: 'GET',
    url: '/'+ change_url +'/delUb',
    params:{id}
})
/**
 * 刪除網格
 * @param uid
 * @returns {AxiosPromise}
 */
export const delGrid = (uid, state) => request({
    method: 'POST',
    url: '/grid/delGridStrategy',
    params:{uid, state}
})
/**
 * 啓動停止網格策略
 * @param uid
 * @param action
 * @returns {AxiosPromise}
 */
export const startOrPauseGridStrategy = (uid, action) => request({
    method: 'POST',
    url: '/grid/startOrPauseGridStrategy',
    params:{uid, action}
})
/**
 * 獲取策略詳情
 * @param uid
 * @returns {AxiosPromise}
 */
export const getGridStrategy = (uid,requestTimes) => request({
    method: 'POST',
    url: '/grid/getGridStrategyByUid',
    params:{uid,requestTimes}
})
/**
 * 獲取買單挂單列表
 * @param side
 * @returns {AxiosPromise}
 */
export const getNoDealSellOrderList = (uid, side, requestTimes) => request({
    method: 'POST',
    url: '/grid/getNoDealOrderList',
    params: {uid,side,requestTimes}
})
/**
 * 歷史策略
 * @param robotType
 * @param pageNum
 * @param pageSize
 * @returns {AxiosPromise}
 */
export const getGridStrategyHistoryList = (robotType,pageNum,pageSize,requestTimes) => request({
    method: 'POST',
    url: '/grid/getStrategyHistoryList',
    params: {robotType,pageNum,pageSize,requestTimes}
})
/**
 * 成交記錄
 * @param uid
 * @returns {AxiosPromise}
 */
export const getDealOrderList = (uid, pageNum, pageSize, requestTimes) => request({
    method: 'POST',
    url: '/grid/getDealOrderList',
    params: {uid, pageNum, pageSize, requestTimes}
})
/**
 * 詳情
 * @param uid
 * @param buyOrderId
 * @param sellOrderId
 * @returns {AxiosPromise}
 */
export const getDealOrderDetails = (uid,buyOrderId,sellOrderId) => request({
    method: 'POST',
    url: '/grid/getDealOrderDetails',
    params:{uid,buyOrderId,sellOrderId}
})
export const getTickerPrice = (symbol,robotType,requestTimes) => request({
    method: 'POST',
    url: '/grid/getTickerPrice',
    params:{symbol,robotType,requestTimes}
})
/**
 * 获取币种的可用余额
 * @param coin
 * @param robotType
 * @returns {AxiosPromise}
 */
export const getCoinBalance = (coin,robotType,type,instType,futuresType) => request({
    method: 'POST',
    url: '/grid/getCoinBalance',
    params:{coin,robotType,type,instType,futuresType}
})


/**
 * 获取當前委托
 * @returns {AxiosPromise}
 */
export const getSellOrdersByState = (token,state,pageNum,pageSize) => request({
    method: 'GET',
    url: '/'+ change_url +'/getSellOrdersByState',
    params:{token,state,pageNum,pageSize}
})
export const getOrderListByStateAndSymbol = (state,startDate,endDate,productType,symbol,pageNum,pageSize,robotType,uidOne, uidTwo) => request({
    method: 'POST',
    // url: '/'+ change_url+'/getOrderListByStateAndSymbol',
    url: '/'+ (robotType==0?'robot':'okex')+'/getOrderListByStateAndSymbol',
    params:{state,startDate,endDate,productType,symbol,pageNum,pageSize,uidOne, uidTwo}
})

/**
 * 获取當前委托
 * @returns {AxiosPromise}
 */
export const getSellOrdersByState2 = (token,state,startDate,endDate,pageNum,pageSize) => request({
    method: 'GET',
    url: '/'+ change_url +'/getSellOrdersByState',
    params:{token,state,startDate,endDate,pageNum,pageSize}
})

/**
 * 保存机器人昵称
 * @returns {AxiosPromise}
 */
export const saveRobotName = (robotName) =>request({
    method: 'GET',
    url: '/' + change_url + '/saveRobotName',
    params:{robotName}
})
/**
 * 本周 开单 榜
 * @returns {AxiosPromise}
 */
export const getUserTradeCountByWeekTop10 = (product_type, order_column, strategy_type, dateType) => request({
    method: 'GET',
    url: '/statistics/userTradeCountByWeekTop10',
    params:{product_type, order_column, strategy_type, dateType}
})
/**
 * 本周 收益 榜
 * @returns {AxiosPromise}
 */
export const getUserProfitByWeekTop10 = (product_type, dateType, strategy_type) => request({
    method: 'GET',
    url: '/statistics/userProfitByWeekTop10',
    params:{product_type, dateType, strategy_type}
})
/**
 * 本周 开单 榜我的排行
 * @returns {AxiosPromise}
 */
export const getTradeCountRankByWeek = (product_type, order_column, dateType, strategy_type, requestTimes) => request({
    method: 'GET',
    url: '/statistics/getTradeCountRankByWeek',
    params:{product_type, order_column, dateType, strategy_type, requestTimes}
})
/**
 * 本周 收益 榜我的排行
 * @returns {AxiosPromise}
 */
export const getMyRankByWeek = (product_type, dateType, requestTimes) => request({
    method: 'GET',
    url: '/statistics/getMyRankByWeek',
    params:{product_type, dateType, requestTimes}
})
/**
 * 本周收益榜我的排行
 * @returns {AxiosPromise}
 */
export const updateUserPower = (token,allowViewPosition) => request({
    method: 'GET',
    url: '/index/updateUserPositionPower',
    params:{token,allowViewPosition}
})
/**
 * 查看他人仓位设置
 * @returns {AxiosPromise}
 */
export const getUserPosition = (rankUserId, productType) => request({
    method: 'GET',
    url: '/robot/getUserPosition',
    params:{rankUserId, productType}
})
export const getUserPositionNew = (rankUserId, productType) => request({
    method: 'GET',
    url: '/robot/getUserPositionAll',
    params:{rankUserId, productType}
})
/**
 * 查看他人仓位设置
 * @returns {AxiosPromise}
 */
export const getUserOkexPosition = (rankUserId, productType) => request({
    method: 'GET',
    url: '/okex/getUserPosition',
    params:{rankUserId, productType}
})
/**
 * 获取用户周开单数
 * @returns {AxiosPromise}
 */
export const getUserWeekTradeCount = (uid) => request({
    method: 'post',
    url: '/statistics/getUserWeekTradeCount',
    params:{uid}
})

/**
 * 获取机器人列表
 */
export const getRobotList = () =>request({
    method:'GET',
    // url:'/index/getRobotList'
    url:'/robot/getRobotList'
})

/**
 * 获取交易对数量
 */
export const getSymbolCount = () =>request({
    method:'get',
    url:'/okex/getSymbolCount'
})
/**
 * 获取马丁详情接口
 * @param uid
 * @returns {AxiosPromise}
 */
export const getMartinStrategy = (uid) => request({
    method : 'POST',
    url: '/martin/getMartinStrategyByUid',
    params: {uid}
})

/**
 * 启动、暂停策略
 * @param uid
 * @param action
 * @returns {AxiosPromise}
 */
export const startOrPauseMartinStrategy = (uid, action) =>request({
    method: 'POST',
    url: '/martin/startOrPauseMartinStrategy',
    params: {uid, action}
})
/**
 * 删除马丁策略
 * @param uid
 * @param state
 * @returns {AxiosPromise}
 */
export const delMartinStrategy = (uid, state) =>request({
    method: 'POST',
    url: '/martin/delMartinStrategy',
    params: {uid, state}
})
/**
 * 1满单止损，2追踪止盈，3追踪补仓
 * @param uid
 * @param action
 * @returns {AxiosPromise}
 */
export const martinStrategyShortcut = (uid, action) =>request({
    method: 'POST',
    url: '/martin/martinStrategyShortcut',
    params: {uid, action}
})
/**
 * 停止or开启补单 action(0仅本次循环停止补单，1开启补单，2本次循环停止补单并停止策略)
 * @param uid
 * @param action
 * @returns {AxiosPromise}
 */
export const stopReplenishment = (uid, action) =>request({
    method: 'POST',
    url: '/martin/stopReplenishment',
    params: {uid, action}
})
/**
 * 清仓卖出 0仅本次循环清仓卖出，2本次循环清仓卖出并停止策略
 * @param uid
 * @param action
 * @returns {AxiosPromise}
 */
export const clearanceSale = (uid, action) =>request({
    method: 'POST',
    url: '/martin/clearanceSale',
    params: {uid, action}
})
/**
 * 一鍵補倉 0继续补仓，1不再补仓
 * @param uid
 * @param action
 * @returns {AxiosPromise}
 */
export const oneClickReplenishment = (uid, action, space, price) => request({
    method: 'POST',
    url: '/martin/oneClickReplenishment',
    params: {uid, action, space, price}
})
/**
 * 馬丁策略循环记录
 * @param uid
 * @returns {AxiosPromise}
 */
export const getStrategyCircleList = (uid,pageNum,pageSize,requestTimes) => request({
    method: 'POST',
    url: '/martin/getStrategyCircleList',
    params: {uid,pageNum,pageSize,requestTimes}
})
/**
 * 策略当前循环成交记录明细
 * @param uid
 * @param multiplex
 * @param numberOfCycles
 * @returns {AxiosPromise}
 */
export const getMartinDealOrderDetails = (uid ,multiplex, numberOfCycles) =>request({
    method: 'POST',
    url: '/martin/getDealOrderDetails',
    params: {uid ,multiplex, numberOfCycles}
})
/**
 * 通过交易对查看可用余额
 * @param baseCoin
 * @param quoteCoin
 * @returns {AxiosPromise}
 */
export const getSymbolInfoByCoins = (baseCoin,quoteCoin) =>request({
    method: 'POST',
    url: '/'+ change_url +'/getSymbolInfoByCoins',
    params: {baseCoin,quoteCoin}
})
/**
 * 该用户是否为内测用户
 * @returns {AxiosPromise}
 */
export const getIsTestUser = ()=> request({
    method: 'POST',
    url: '/robot/getIsTestUser',
})

export const getIsModuleTestUser = (module) =>request({
    method: 'POST',
    url: '/robot/getIsModuleTestUser',
    params: {module}
})

/**
 * 批量启动全自动策略
 * @returns {AxiosPromise}
 */
export const startAutomaticStrategy = (data, state, robotType) => request({
    method: 'POST',
    url: '/robot/startAutomaticStrategy',
    params: {data, state, robotType}
})

/**
 * 校验重复的交易对
 * @param symbol
 * @returns {AxiosPromise}
 */
export const checkSymbolDuplicate = (symbol) => request({
    method: 'POST',
    url: 'robot/checkSymbolDuplicate',
    params: {symbol}
})

/**
 * 暂停合约策略
 * @param robotType
 * @returns {{method: string, params: {robotType}, url: string}}
 */
export const stopSupremeHedge = (robotType, sid) =>request({
    method: 'POST',
    url: 'robot/stopSupremeHedge',
    params: {robotType, sid}
})

/**
 * 开启合约策略
 * @param robotType
 * @returns {{method: string, params: {robotType}, url: string}}
 */
export const startSupremeHedge = (robotType, sid) =>request({
    method: 'POST',
    url: 'robot/startSupremeHedge',
    params: {robotType, sid}
})

/**
 * 删除合约策略
 * @param robotType
 * @returns {AxiosPromise}
 */
export const deleteSupremeHedge = (robotType, sid) =>request({
    method: 'POST',
    url: 'robot/deleteSupremeHedge',
    params: {robotType, sid}
})

/**
 * 至尊合约详情
 * @param robotType
 * @returns {AxiosPromise}
 */
export const getSupremeHedge = (robotType, sid) =>request({
    method: 'POST',
    url: 'robot/getSupremeHedge',
    params: {robotType, sid}
})

/**
 * 当前委托或历史委托  0当前委托，1历史委托
 * @param robotType
 * @param state
 * @returns {AxiosPromise}
 */
export const getSupremeHedgeOrder = (state, startDate, endDate, pageNum, pageSize, robotType, type, sid)=>request({
    method: 'POST',
    url: 'robot/getSupremeHedgeOrder',
    params: {state, startDate, endDate, pageNum, pageSize, robotType, type, sid}
})

/**
 * 获取当前仓位或历史仓位，参数：robotType，state：0当前仓位，1历史仓位
 * @param state
 * @param startDate
 * @param endDate
 * @param pageNum
 * @param pageSize
 * @param robotType
 * @returns {AxiosPromise}
 */
export const getSupremeHedgeSpace = (state, startDate, endDate, pageNum, pageSize, robotType, sid) => request({
    method: 'POST',
    url: '/robot/getSupremeHedgeSpace',
    params: {state, startDate, endDate, pageNum, pageSize, robotType, sid}
})

/**
 * 获取合约最小仓位限制
 * @param {*} coin
 * @param {*} lever 杠杆倍数 （暂不用传）
 * @param {*} robotType
 */
export const getFuturesMinSpace = (coin, lever, robotType) =>request({
    method: 'GET',
    url:'/robot/getFuturesMinSpace',
    params: {coin, lever, robotType}
})

/**
 * 预警列表
 * @param {*} level 1警告，2错误
 * @param {*} type 1用户，2交易所，3策略
 * errorType错误类型：1资金不足，2仓位不足，3亏损大于50%，4API无效，5API没有划转权限，6燃料费不足
 * level异常等级：1警告，2错误
 */
export const getUserFuturesException = (robotType, requestTimes) => request({
    method: 'GET',
    url:'/other/getUserFuturesException',
    params: {robotType, requestTimes}
})

/**
 * 补充亏损
 * @param {*} robotType
 * @param {*} sid
 * @param {*} space
 */
export const complementFuturesSpace = (robotType, sid, space) => request({
    method: 'GET',
    url: '/other/complementFuturesSpace',
    params: {robotType, sid, space}
})

/**
 * 期现对冲策略 最小下单数量
 */
export const minimum_spot_hedging = (coin, robot_type) =>requestOther({
    method: 'POST',
    url: '/minimum_order_quantity_term_hedging',
    data: {coin, robot_type}
})

/**
 * 创建期现对冲
 */
export const addTermHedging = (user_id, coin, space, robot_type) =>requestOther({
    method: 'POST',
    url: '/add_term_hedging',
    data: {user_id, coin, space, robot_type}
})

/**
 * 修改期现仓位
 */
export const modify_term_hedging = (params) =>requestOther({
    method: 'POST',
    url: 'modify_bin_term_hedging',
    data: params
})

/**
 * 删除期现策略
 */
export const deleteTermHedging = (user_id, coin, robot_type) =>requestOther({
    method: 'POST',
    url: '/delete_term_hedging',
    data: {user_id, coin, robot_type}
})

/**
 * 收益接口
 */
export const profitTermHedging = (params) =>requestOther({
    method: 'POST',
    url: 'profit_term_hedging',
    data: params
})

/**
 * 当前仓位信息
 */
export const positionTermHedgin = (user_id, coin, robot_type) =>requestOther({
    method: 'POST',
    url: 'position_term_hedging',
    data: {user_id, coin, robot_type}
})

/**
 * 委托单查询
 */
export const orderListHedging = (user_id, coin, pagenum, pagesize) =>requestOther({
    method: 'POST',
    url: 'order_list_term_hedging',
    data: {user_id, coin, pagenum, pagesize}
})

/**
 * 获取期现最小仓位列表
 */
// export const minimumOrderListTerm = (coin_list, coin_sort) =>requestOther({
export const minimumOrderListTerm = (coin_sort, robot_type) =>requestOther({
    method: 'POST',
    url: '/minimum_order_list_quantity_term_hedging',
    data: {coin_sort, robot_type}
})

/**
 * 获取合约所有币种的最小仓位
 * @param coin_sort
 * @param robot_type
 * @returns {AxiosPromise}
 */
export const getFuturesMinSpaceAll = (coin_sort, robot_type) =>request({
    method: 'GET',
    url: '/robot/getFuturesMinSpaceAll',
    params: {coin_sort, robot_type}
})






