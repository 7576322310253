import axios from 'axios'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import router from "../router";
var loading = ''
let isFirstMessage = 1
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://api.felton.fit/',
  // 超时
  timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const token = localStorage.getItem('token');
  let params = {}
  let data = {}
  if(config.method === 'POST') {
    if (!config.data) {
      data.token = token
      config.data = data
    } else {
      config.data.token = token
    }
  }
  if (config.method === 'GET') {
    if (!config.params) {
      params.token = token
      config.params = params
    } else {
      config.params.token = token
    }
  }
  // get请求映射params参数
  if (config.method === 'GET' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
      if (res.data.requestTimes == 10) {

      } else {
        loading = Loading.service({
          lock: false,
          text: '加载中……',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      delete res.data.requestTimes
      setTimeout(() => {
        loading.close()
      }, 10000)
      if (res.status == 500) router.replace('/Server_error')
      if (res.status == 404) router.replace('/Not_found')
      loading.close();
      if (res.data.toString().indexOf('not invalid token') !== -1) {
        if (isFirstMessage == 1) {
          router.replace('/login')
          window.localStorage.removeItem('token')
          // window.localStorage.removeItem(JSON.parse(localStorage.getItem('userInfo')).id+'\'1-1-1')
          window.localStorage.removeItem('userInfo')
          Message.warning('登录身份已过期,请重新登录 !')
          isFirstMessage++
          return;
        }
        return
      } else {
        if (window.localStorage.getItem('token'))
          isFirstMessage = 1
      }
      return res.data
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
